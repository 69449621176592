<template>
  <div>
    <el-dialog :title="$t('messages.editObject')" :visible.sync="AttrDialogVisible" :modal-append-to-body="false"
      :width="width" ref="attrDialog" :before-close="handleClose" @opened="opened">
      <div class="dialog-body">
        <div class="dialog-obj">
          <div class="dialog-obj__title">{{ $t('messages.selectObjList') }}</div>
          <div class="dialog-obj__body">
            <div class="obj-item" v-if="deviceList.length > 0">
              <div @click="showDevice = !showDevice">
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showDevice"></i>
                <i class="el-icon-remove-outline item-icom" v-else></i>
                &nbsp;{{ $t('messages.Component') }}[{{deviceList.length}}]
              </div>
              <el-collapse-transition>
                <div v-show="showDevice">
                  <div v-for="(v, k) in deviceList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndex == v.type + 'd-' + k }"
                    @click="checkoutDeviceInfo(v, k, false)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="visible_graphList.length > 0">
              <div>
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showGraph" @click="showGraph = true"></i>
                <i class="el-icon-remove-outline item-icom" v-else @click="showGraph = false"></i>
                <div style="display: inline-block" @click="graphClick">
                  &nbsp;{{ $t('messages.graph') }}[{{visible_graphList.length}} /
                  {{visible_graphList.length + graphList.length}}]</div>
              </div>
              <el-collapse-transition>
                <div v-show="showGraph">
                  <div v-for="(v, k) in visible_graphList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndexList.indexOf(v.type + 'g-' + k) != -1 }"
                    @click="checkoutGraphInfo(v, k, false, $event)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="keypointList.length > 0">
              <div @click="showKeyPoint = !showKeyPoint">
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showKeyPoint"></i>
                <i class="el-icon-remove-outline item-icom" v-else></i>
                &nbsp;{{ $t('messages.KeyPoint') }}[{{keypointList.length}}]
              </div>
              <el-collapse-transition>
                <div v-show="showKeyPoint">
                  <div v-for="(v, k) in keypointList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndex == v.type + 'k-' + k }"
                    @click="checkoutKeyPoint(v, k, false)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="labelList.length > 0">
              <div @click="showTag = !showTag">
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showTag"></i>
                <i class="el-icon-remove-outline item-icom" v-else></i>
                &nbsp;{{ $t('messages.Label') }}[{{labelList.length}}]
              </div>
              <el-collapse-transition>
                <div v-show="showTag">
                  <div v-for="(v, k) in labelList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndex == v.type + 'l-' + k }"
                    @click="checkoutLabel(v, k, false)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="rulerList.length > 0">
              <div @click="showRuler = !showRuler">
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showRuler"></i>
                <i class="el-icon-remove-outline item-icom" v-else></i>
                &nbsp;{{ $t('messages.Staff') }}[{{rulerList.length}}]
              </div>
              <el-collapse-transition>
                <div v-show="showRuler">
                  <div v-for="(v, k) in rulerList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndex == v.type + 'r-' + k }"
                    @click="checkoutRuler(v, k, false)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div class="obj-item" v-if="textList.length > 0">
              <div @click="showText = !showText">
                <i class="el-icon-circle-plus-outline item-icom" v-if="!showText"></i>
                <i class="el-icon-remove-outline item-icom" v-else></i>
                &nbsp;{{ $t('messages.Text') }}[{{textList.length}}]
              </div>
              <el-collapse-transition>
                <div v-show="showText">
                  <div v-for="(v, k) in textList" :key="v.type + k"
                    :class="{ item__item: true, selected: selectIndex == v.type + 't-' + k }"
                    @click="checkoutTextInfo(v, k, false)">
                    <el-tooltip class="item" effect="light" :content="v.display_name" placement="right">
                      <div>{{ v.display_name }}</div>
                    </el-tooltip>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
            <div style="width:1px;height:1px;" id="sentinel" ref="sentinel"></div>
          </div>
        </div>
        <!-- 属性窗口 -->
        <div class="dialog-main" v-if="selectText">
          <div class="main-tab">
            <div :class="{ 'tab-item': true, selected: selectAttr }"
              @click=";(selectAttr = true), (selectParams = false)">{{ $t('messages.attrs') }}</div>
            <div :class="{ 'tab-item': true, selected: selectParams }"
              @click=";(selectAttr = false), (selectParams = true)">{{ $t('messages.params') }}</div>
          </div>
          <div class="main-body" v-if="selectObj && selectAttr">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" style="width: 440px" v-model.trim="selectObj_params.name"
                disabled></el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Rotate') }}：</div>
              <el-input-number @blur="recordChange" :precision="0" @keyup.native="rotateNumberKeyUp"
                style="width: 175px" v-model.trim="selectObj_params.trans_angle" :controls="false"></el-input-number>
              &nbsp;
              <div style="font-size: 18px; display: inline-block">°</div>
            </div>
            <div class="body-item">
              <div style="width: 120px">{{ $t('messages.centerPoint') }}：</div>
              <div class="body-item">
                <div style="width: 30px">X：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[0]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
              <div class="body-item" style="margin-left: 50px">
                <div style="width: 30px">Y：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[1]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
            </div>
          </div>
          <div class="main-body" v-if="selectParams && selectObj">
            <!-- <div class="body-item">
              <div style="width: 120px">{{ $t('messages.ReferenceLocation') }}：</div>
              <el-select @change="recordChange" v-model.trim="selectObj_params.anchor" style="width: 400px">
                <el-option v-for="item in $t('messages.directionOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div> -->
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.layer') }}：</div>
              <el-select @blur="recordChange" v-model.trim="selectObj_params.layer" style="width: 440px"
                @change="layerChange">
                <el-option v-for="item in layerOptions" :key="item.id" :label="item.label" :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Size') }}：</div>
              <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 145px"
                v-model.trim="selectObj_params.size" :controls="false"></el-input-number>
              &nbsp;um
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.aText') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" maxlength="20" style="width: 440px"
                v-model.trim="selectObj_params.text"></el-input>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectGraphic && selectIndexList.length < 2">
          <div class="main-tab" v-if="haveParams">
            <div :class="{ 'tab-item': true, selected: selectAttr }"
              @click=";(selectAttr = true), (selectParams = false), initPointData()">{{ $t('messages.attrs') }}</div>
            <div :class="{ 'tab-item': true, selected: selectParams }"
              @click=";(selectAttr = false), (selectParams = true)">{{ $t('messages.params') }}</div>
          </div>
          <div class="main-tab" v-if="!haveParams">
            <div class="tab-item">{{ $t('messages.attrs') }}</div>
          </div>
          <div class="main-body" v-if="selectObj && selectAttr">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 440px"
                v-model.trim="selectObj_params.display_name" disabled></el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Rotate') }}：</div>
              <el-input-number @blur="recordChange" :precision="0" @keyup.native="rotateNumberKeyUp"
                style="width: 175px" v-model.trim="selectObj_params.trans_angle" :controls="false"></el-input-number>
              &nbsp;
              <div style="font-size: 18px; display: inline-block">°</div>
            </div>
            <div class="body-item" v-if="selectObj.type != 0">
              <div style="width: 120px">{{ $t('messages.centerPoint') }}：</div>
              <div class="body-item">
                <div style="width: 30px">X：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.center[0]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
              <div class="body-item" style="margin-left: 50px">
                <div style="width: 30px">Y：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.center[1]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
            </div>
          </div>
          <div class="main-body" v-if="selectParams && selectObj">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.layer') }}：</div>
              <el-select @blur="recordChange" v-model.trim="selectObj_params.layer" style="width: 440px"
                @change="layerChange">
                <el-option v-for="item in layerOptions" :key="item.id" :label="item.label" :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div>
            <div v-if="selectObj.type == 0">
              <div class="body-item">
                <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.lineWidth') }}：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 115px"
                  v-model="selectObj_params.width" :controls="false"></el-input-number>
                &nbsp;um
              </div>
              <div class="body-item" style="margin-left: 50px;">
                <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.CenterLineWidth")}}：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 115px;"
                  v-model.trim="selectObj_params.center_width" :controls="false"></el-input-number>&nbsp;um
              </div>
              <div class="body-item">
                <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.TurningRadius') }}：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 115px"
                  v-model="selectObj_params.radius" :controls="false"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="body-item" v-if="selectObj.type == 0 || selectObj.type == 2" v-loading="point_loading">
              <el-table :data="selectObj_params.points" class="t-table" header-cell-class-name="table_header"
                style="height:365px;overflow-y:auto;" ref="pointTable" v-infinite-scroll="loadMore"
                :infinite-scroll-disabled="selectObj_params.points_all.length==0">
                <el-table-column :label="$t('messages.orderNumber')" type="index" align="center"
                  width="50"></el-table-column>
                <el-table-column :label="$t('messages.points')">
                  <template slot-scope="scope">
                    <div style="display: inline-block; margin-right: 25px">
                      X：
                      <el-input-number @focus="onInputFocus" @change="((val)=>{changePointCheck(val, scope.row, 0)})"
                        @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" v-model.trim="scope.row[0]"
                        :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                    <div style="display: inline-block">
                      Y：
                      <el-input-number @focus="onInputFocus" @change="((val)=>{changePointCheck(val, scope.row, 1)})"
                        @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" v-model.trim="scope.row[1]"
                        :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.pointLength')" v-if="selectObj.type == 0" width="110">
                  <template slot-scope="scope">
                    <div v-if="scope.$index < selectObj_params.points.length - 1">
                      <div class="distabce-1">d</div>
                      <div class="distabce-2">{{ scope.$index + 1 }}-{{ scope.$index + 2 }}</div>
                      <div>
                        ={{ getPointDist(scope.row[0], scope.row[1], selectObj_params.points[scope.$index + 1][0], selectObj_params.points[scope.$index + 1][1]) }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="distabce-1">d</div>
                      <div class="distabce-2">{{ $t('messages.all') }}</div>
                      <div>={{ getPointDist_Total(selectObj_params.points) }}</div>
                    </div>
                    <!-- <div class="distabce-1">d</div>
                    <div class="distabce-2">{{scope.$index + 1}}-{{scope.$index + 2}}</div>
                    <div v-if="scope.$index+1 < scope.row.length">
                      ={{getPointDist(scope.row[0], scope.row[1], selectObj.shape[scope.$index+1][0], selectObj.shape[scope.$index+1][1])}}
                    </div>
                    <div v-else>
                      ={{getPointDist(scope.row[0], scope.row[1], selectObj.shape[0][0], selectObj.shape[0][1])}}
                    </div> -->
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.operation')" width="110" align="center">
                  <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="$t('messages.copy')" placement="bottom">
                      <img alt="" @click="copyPoint(scope.$index)" class="operateIcon"
                        src="../../assets/icons/edit/复制.svg" />
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('messages.delete')" placement="bottom">
                      <img alt="" @click="deletePoint(scope.$index)" class="operateIcon"
                        src="../../assets/icons/edit/删除.svg" />
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
              <div>{{$t('messages.pointsCount')}}:
                {{`${selectObj_params.points.length} / ${selectObj_params.total_points}`}}</div>
            </div>
            <div v-if="selectObj.type == 3 || selectObj.type == 1">
              <div style="display: flex; flex-wrap: wrap" v-if="selectObj.type == 3">
                <div class="body-item">
                  <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.radiusX') }}：</div>
                  <el-input-number @blur="recordChange" :min="0.001" :precision="3" @focus="onInputFocus"
                    @change.native="radiusWidthHeightNumberKeyUp" style="width: 115px"
                    v-model.trim="selectObj_params.radius_x" :controls="false"></el-input-number>
                  &nbsp;um
                </div>
                <div class="body-item" style="margin-left: 50px">
                  <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.radiusY') }}：</div>
                  <el-input-number @blur="recordChange" :min="0.001" :precision="3" @focus="onInputFocus"
                    @change.native="radiusWidthHeightNumberKeyUp" style="width: 115px"
                    v-model.trim="selectObj_params.radius_y" :controls="false"></el-input-number>
                  &nbsp;um
                </div>
                <div class="body-item">
                  <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.maxPoints') }}：</div>
                  <el-input-number @blur="recordChange" :min="4" :precision="0" @focus="onInputFocus"
                    style="width: 115px" v-model.trim="selectObj_params.max_points" :controls="false"></el-input-number>
                  &nbsp;
                </div>
              </div>
              <div class="body-item" v-if="selectObj.type == 1">
                <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.wide') }}：</div>
                <el-input-number @blur="recordChange" :min="0.001" :precision="3" @focus="onInputFocus"
                  @change.native="radiusWidthHeightNumberKeyUp" style="width: 140px"
                  v-model.trim="selectObj_params.width" :controls="false"></el-input-number>
                &nbsp;um
                <div class="body-item" style="margin-left: 50px">
                  <div style="width: 50px">{{ $t('messages.high') }}：</div>
                  <el-input-number @blur="recordChange" :min="0.001" :precision="3" @focus="onInputFocus"
                    @change.native="radiusWidthHeightNumberKeyUp" style="width: 140px"
                    v-model.trim="selectObj_params.height" :controls="false"></el-input-number>
                  &nbsp;um
                </div>
              </div>
              <!-- <div class="body-item">
                <div style="width: 80px;font-size:12px;color:#666666;">左下角：</div>
                <div class="body-item">
                  <div style="width: 50px;">X：</div>
                  <el-input-number @blur="recordChange"  :precision="3" @keyup.native="numberKeyUp" style="width: 80px;font-size:12px;color:#666666;" @blur="changeXY(selectObj)" v-model.trim="selectObj_params.aabb[0][0]" :controls="false"></el-input-number>&nbsp;um
                </div>
                <div class="body-item" style="margin-left: 80px;">
                  <div style="width: 50px;">Y：</div>
                  <el-input-number @blur="recordChange"  :precision="3" @keyup.native="numberKeyUp" style="width: 80px;font-size:12px;color:#666666;" v-model.trim="selectObj_params.aabb[0][1]" :controls="false"></el-input-number>&nbsp;um
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectGraphic && selectIndexList.length > 1">
          <div class="main-tab">
            <div class="tab-item">{{ $t('messages.sameParams') }}</div>
          </div>
          <div class="main-body">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.layer') }}：</div>
              <el-select @blur="recordChange" v-model.trim="selectObj_many_graph_layer" style="width: 440px"
                @change="layerListChange">
                <el-option v-for="item in layerOptions" :key="item.id" :label="item.label" :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectDevice">
          <div class="main-tab" v-if="haveParams">
            <div :class="{ 'tab-item': true, selected: selectAttr }"
              @click=";(selectAttr = true), (selectParams = false)">{{ $t('messages.attrs') }}</div>
            <div :class="{ 'tab-item': true, selected: selectParams }"
              @click=";(selectAttr = false), (selectParams = true)">{{ $t('messages.params') }}</div>
          </div>
          <div class="main-tab" v-if="!haveParams">
            <div class="tab-item">{{ $t('messages.attrs') }}</div>
          </div>
          <div class="main-body" v-if="selectAttr && selectObj">
            <div class="body-item">
              <div class="item-title">{{ $t('messages.userAndTeamName') }}：</div>
              <el-select style="width: 255px" v-model.trim="this.$store.state.topInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.project') }}：</div>
              <el-select style="width: 255px" v-model.trim="this.$store.state.proInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.fileName') }}：</div>
              <el-select style="width: 255px" v-model.trim="this.$store.state.fileInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.cellName') }}：</div>
              <el-select style="width: 255px" v-model.trim="this.$store.state.cellInfo.name" disabled></el-select>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 255px"
                v-model="selectObj.obj_params.display_name" disabled></el-input>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.Rotate') }}：</div>
              <el-input-number @blur="recordChange" :precision="0" @keyup.native="rotateNumberKeyUp"
                style="width: 175px" v-model.trim="selectObj.obj_params.trans_angle"
                :controls="false"></el-input-number>
              &nbsp;
              <div style="font-size: 18px; display: inline-block">°</div>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.originPoint') }}：</div>
              <div>
                X：
                <div>
                  <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.origin[0]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
              <div style="margin-left: 35px">
                Y：
                <div>
                  <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.origin[1]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.rowsAndColumns') }}：</div>
              <div>
                X：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.rows" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
              <div style="margin-left: 35px">
                Y：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.columns" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.rowVector') }}：</div>
              <div>
                X：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.v2[0]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
              <div style="margin-left: 35px">
                Y：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.v2[1]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
            </div>
            <div class="body-item">
              <div class="item-title">{{ $t('messages.columnVector') }}：</div>
              <div>
                X：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.v1[0]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
              <div style="margin-left: 35px">
                Y：
                <div>
                  <el-input-number @blur="recordChange" :precision="0" @keyup.native="numberKeyUp"
                    v-model.trim="selectObj.obj_params.repetition.v1[1]" style="width: 100px"
                    :controls="false"></el-input-number>
                </div>
              </div>
            </div>
            <div class="body-item" v-if="deviceKeyPointList.length > 0">
              <el-table :data="deviceKeyPointParamsList" class="t-table_keyPoint" header-cell-class-name="table_header"
                height="150px">
                <el-table-column :label="$t('messages.keypointName')" align="center" width="100">
                  <template slot-scope="scope">
                    <div>{{ scope.row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('messages.points')">
                  <template slot-scope="scope">
                    <div style="display: inline-block; margin-right: 30px">
                      X：
                      <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                        v-model.trim="scope.row.point[0]" @change="changeDeviceKeyPoint(scope.row)"
                        :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                    <div style="display: inline-block">
                      Y：
                      <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                        v-model.trim="scope.row.point[1]" @change="changeDeviceKeyPoint(scope.row)"
                        :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="main-body" v-if="selectParams">
            <div class="body-item" v-if="pad_length">
              <div class="item-label">{{ $t('messages.totalLength') }}：</div>
              <el-input-number @blur="recordDevice" :precision="3" @keyup.native="numberKeyUp" style="width: 275px"
                v-model.trim="pad_length" :controls="false" disabled></el-input-number>
              &nbsp;um
            </div>
            <div v-for="k in Object.keys(selectObj.obj_params.params)" :key="k">
              <div class="body-item" style="margin-bottom: 10px"
                v-if="k.indexOf('datatype') == -1 && k.indexOf('layer') == -1">
                <div v-if="k.indexOf('tolerance') != -1" title="数值越小越接近圆，数值越大越接近多边形">
                  <div class="item-label">
                    {{ k }}（{{ $t('messages.tolerance') }})({{ selectObj.obj_params.params[k].min }}~{{ selectObj.obj_params.params[k].max }})：
                  </div>
                  <el-input-number @change="changeParams" :precision="3" @keyup.native="numberKeyUp"
                    style="width: 275px" :min="selectObj.obj_params.params[k].min"
                    :max="selectObj.obj_params.params[k].max" v-model.trim="selectObj.obj_params.params[k].value"
                    :controls="false"></el-input-number>
                  &nbsp;um
                </div>
                <div v-else-if="k.indexOf('GapType') != -1">
                  <div class="item-label">{{ k }}</div>
                  <el-select v-model.trim="selectObj.obj_params.params[k].value" @change="changeParams"
                    style="width: 275px">
                    <el-option v-for="item in selectObj.obj_params.params[k].options" :key="item.value"
                      :label="item.label" :value="item.value">
                      <div style="padding-left: 10px">{{ item.label }}</div>
                    </el-option>
                  </el-select>
                </div>
                <div v-else>
                  <div v-if="k=='n' && selectObj.obj_params.function_name=='Double_Channle_FDSOI'">
                    <div class="item-label">
                      {{ k }}({{$t('messages.even_number')}})({{ selectObj.obj_params.params[k].min }}~{{ selectObj.obj_params.params[k].max }})：
                    </div>
                    <el-input-number :precision="3" @keyup.native="paramsNumberKeyUp" style="width: 275px"
                      :min="selectObj.obj_params.params[k].min" :max="selectObj.obj_params.params[k].max"
                      @change.native="specialParamsNumberKeyChange($event, k)"
                      v-model.trim="selectObj.obj_params.params[k].value" :controls="false"></el-input-number>
                    &nbsp;um
                  </div>
                  <div v-else>
                    <div class="item-label">
                      {{ k }}({{ selectObj.obj_params.params[k].min }}~{{ selectObj.obj_params.params[k].max }})：</div>
                    <el-input-number :precision="3" @keyup.native="paramsNumberKeyUp" style="width: 275px"
                      :min="selectObj.obj_params.params[k].min" :max="selectObj.obj_params.params[k].max"
                      @change="changeParams" v-model.trim="selectObj.obj_params.params[k].value"
                      :controls="false"></el-input-number>
                    &nbsp;um
                  </div>
                </div>
              </div>
              <div class="body-item" v-show="k.indexOf('layer') != -1">
                <div class="item-label">{{ layerTransForm(k) }}：</div>
                <el-select v-model="selectObj.obj_params.params[k]" @change="changeLayerParams" style="width: 275px">
                  <el-option v-for="item in layerOptions" :key="item.id" :label="item.label" :value="item.value">
                    <div style="padding-left: 10px">{{ item.label }}</div>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectLabel">
          <div class="main-tab">
            <div :class="{ 'tab-item': true, selected: selectAttr }"
              @click=";(selectAttr = true), (selectParams = false)">{{ $t('messages.attrs') }}</div>
            <div :class="{ 'tab-item': true, selected: selectParams }"
              @click=";(selectAttr = false), (selectParams = true)">{{ $t('messages.params') }}</div>
          </div>
          <div class="main-body" v-if="selectObj && selectAttr">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 440px"
                v-model.trim="selectObj_params.display_name" disabled></el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Rotate') }}：</div>
              <el-input-number @blur="recordChange" :precision="0" @keyup.native="rotateNumberKeyUp"
                style="width: 175px" v-model.trim="selectObj_params.trans_angle" :controls="false"></el-input-number>
              &nbsp;
              <div style="font-size: 18px; display: inline-block">°</div>
            </div>
            <div class="body-item">
              <div style="width: 120px">{{ $t('messages.ReferencePoint') }}：</div>
              <div class="body-item">
                <div style="width: 30px">X：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[0]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
              <div class="body-item" style="margin-left: 50px">
                <div style="width: 30px">Y：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[1]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
            </div>
            <div class="body-item">
              <div style="width: 120px">{{ $t('messages.ReferenceLocation') }}：</div>
              <el-select @change="recordChange" v-model.trim="selectObj_params.anchor" style="width: 400px">
                <el-option v-for="item in $t('messages.directionOption')" :key="item.value" :label="item.label"
                  :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="main-body" v-if="selectParams && selectObj">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.layer') }}：</div>
              <el-select @blur="recordChange" v-model.trim="selectObj_params.layer" style="width: 440px"
                @change="layerChange">
                <el-option v-for="item in layerOptions" :key="item.id" :label="item.label" :value="item.value">
                  <div style="padding-left: 10px">{{ item.label }}</div>
                </el-option>
              </el-select>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Size') }}：</div>
              <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 175px"
                v-model.trim="selectObj_params.font_size" :controls="false"></el-input-number>
              &nbsp;um
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.aText') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 440px" maxlength="20"
                v-model.trim="selectObj_params.text" :placeholder="$t('messages.placeholder_1')"></el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Font') }}：</div>
              <el-select v-model.trim="selectObj_params.layer" style="width: 440px" disabled>
                <!-- <el-option
                  v-for="item in layerOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option> -->
              </el-select>
            </div>
          </div>
        </div>
        <div class="dialog-main" v-if="selectKeyPoint">
          <!-- <div class="main-tab">
            <div :class="{'tab-item': true, selected: selectAttr}" @click="selectAttr=true,selectParams=false">{{$t("messages.attrs")}}</div>
            <div :class="{'tab-item': true, selected: selectParams}" @click="selectAttr=false,selectParams=true">{{$t("messages.params")}}</div>
          </div> -->
          <div class="main-tab">
            <div class="tab-item">{{ $t('messages.attrs') }}</div>
          </div>
          <div class="main-body" v-if="selectObj && selectAttr">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 440px"
                v-model.trim="selectObj_params.text"></el-input>
            </div>
            <!-- <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">{{$t("messages.Rotate")}}：</div>
              <el-input-number @blur="recordChange"  :precision="3" @keyup.native="numberKeyUp" style="width: 175px;" v-model.trim="selectObj.rotation" :controls="false"></el-input-number>&nbsp;度
            </div> -->
            <div class="body-item">
              <div style="width: 120px">{{ $t('messages.centerPoint') }}：</div>
              <div class="body-item">
                <div style="width: 30px">X：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[0]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
              <div class="body-item" style="margin-left: 50px">
                <div style="width: 30px">Y：</div>
                <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp" style="width: 100px"
                  v-model.trim="selectObj_params.origin[1]" :controls="false"></el-input-number>
                &nbsp;um
              </div>
            </div>
          </div>
          <!-- <div class="main-body" v-if="selectParams && selectObj">
            <div class="body-item">
              <div style="width: 80px;font-size:12px;color:#666666;">形状：</div>
              <el-select v-model.trim="selectObj.layer" style="width: 440px;">
                <el-option
                  v-for="item in layerOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  <div style="padding-left:10px;">{{item.label}}</div>
                </el-option>
              </el-select>
            </div>
            <div class="body-item">
              <div style="width: 180px;">上级器件继承：</div>
              <el-radio v-model.trim="selectObj.QDATA.x_reflection" :label="true">是</el-radio>
              <el-radio v-model.trim="selectObj.QDATA.x_reflection" :label="false">否</el-radio>
            </div>
          </div> -->
        </div>
        <div class="dialog-main" v-if="selectRuler">
          <div class="main-tab">
            <div class="tab-item">{{ $t('messages.attrs') }}</div>
          </div>
          <div class="main-body" v-if="selectObj && selectAttr">
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.name') }}：</div>
              <el-input @blur="recordChange" @keyup.native="diykeyUp" style="width: 440px"
                v-model.trim="selectObj_params.display_name" disabled></el-input>
            </div>
            <div class="body-item">
              <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.Rotate') }}：</div>
              <el-input-number @blur="recordChange" :precision="0" @keyup.native="rotateNumberKeyUp"
                style="width: 175px" v-model.trim="selectObj_params.trans_angle" :controls="false"></el-input-number>
              &nbsp;
              <div style="font-size: 18px; display: inline-block">°</div>
            </div>
            <div class="body-item">
              <div class="body-item">
                <div style="width: 80px; font-size: 12px; color: #666666">{{ $t('messages.length') }}：</div>
                <!-- <el-input-number @blur="recordChange"  :precision="3" @keyup.native="numberKeyUp" style="width: 175px;" v-model.trim="selectObj.rotation" :controls="false" disabled></el-input-number>&nbsp;um -->
                <div class="input-diabled">
                  {{ getPointDist(selectObj_params.points[0][0], selectObj_params.points[0][1], selectObj_params.points[1][0], selectObj_params.points[1][1]) }}
                </div>
              </div>
              <el-table :data="selectObj_params.points" class="t-table" header-cell-class-name="table_header"
                height="130px">
                <el-table-column :label="$t('messages.orderNumber')" type="index" align="center"
                  width="100"></el-table-column>
                <el-table-column :label="$t('messages.points')">
                  <template slot-scope="scope">
                    <div style="display: inline-block; margin-right: 80px">
                      X：
                      <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                        style="width: 80px" v-model.trim="scope.row[0]" :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                    <div style="display: inline-block">
                      Y：
                      <el-input-number @blur="recordChange" :precision="3" @keyup.native="numberKeyUp"
                        style="width: 80px" v-model.trim="scope.row[1]" :controls="false"></el-input-number>
                      &nbsp;um
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="body-item">
                <div style="width: 80px; font-size: 12px; color: #666666; margin-left: 40px">
                  {{ $t('messages.distance') }}</div>
                <div>
                  <div class="distabce-1">d</div>
                  <div class="distabce-2">x</div>
                  <div>={{ getPointDist(selectObj_params.points[0][0], 0, selectObj_params.points[1][0], 0) }}um</div>
                </div>
                <div style="margin-left: 30px">
                  <div class="distabce-1">d</div>
                  <div class="distabce-2">y</div>
                  <div>={{ getPointDist(0, selectObj_params.points[0][1], 0, selectObj_params.points[1][1]) }}um</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="mark_img" class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 10px">{{ $t('messages.NoteFigure') }}</div>
          </div>
          <div class="Ann_image__body">
            <div class="ann-image" v-loading="markImgLoading">
              <div v-for="(k, v) in mark_img_list" :key="v">
                <viewer>
                  <img alt="" :src="k" />
                </viewer>
              </div>
            </div>
          </div>
        </div>
        <div v-if="thumbnail_img" class="Ann_image">
          <div class="Ann_image__header">
            <div style="padding: 10px">{{ $t('messages.img_2D') }}</div>
          </div>
          <div class="Ann_image__body_2D">
            <!-- <img alt="" class="ann-image" src="../../assets/icons/tools/TiNPadInpillar-规则.svg"> -->
            <canvas width="275" height="275" id="thumbnail_img_device"></canvas>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="mini" @click="submitUpdateAttrs(false)"
          :loading="updateLoading">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" size="mini" @click="submitUpdateAttrs(true)"
          :loading="updateLoading">{{ $t('messages.complete') }}</el-button>
        <el-button type="cancel" @click="cancelUpdateAttrs" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  pointDist,
  deepClone,
  getPointDistTotal,
  same,
  deepCopy,
} from "@/utils/utils";
import {
  reBuildGDSGraphic,
  updateObjMat,
  rotateObj,
} from "@/utils/graphic_cell/utils/graphics-util";
import {
  updateCharcterCell,
  exChangeCpmponent,
} from "@/utils/graphic_cell/utils/cell-generate";
import {
  fileKernelCell2Gdstk,
  saveCellFn,
} from "@/components/homes/fileList/function/fileListPublicFn";
import { getMarkImg } from "@/api/api";
import bus from "@/components/common/bus";
import { colorTransform } from "../../utils/utils.js";
import { getCellFunc, decompressPropStr } from "../../utils/getcell";
import { getPDKCellFunc } from "../../utils/pdk/getPDKCell.js";
const BigNumber = require("bignumber.js");
const AutoToolCanvasComponent = require("../../utils/tools/canvas_component.js");
var canvas2D = new AutoToolCanvasComponent();
var _ = require("lodash");
export default {
  data() {
    return {
      AttrDialogVisible: false,
      updateLoading: false,
      width: "870px",
      selectAttr: true,
      selectParams: false,
      mark_img: false,
      thumbnail_img: true,
      selectGraphic: true,
      selectDevice: false,
      selectLabel: false,
      selectKeyPoint: false,
      selectRuler: false,
      selectText: false,
      deviceList: [],
      graphList: [],
      keypointList: [],
      labelList: [],
      textList: [],
      rulerList: [],
      graph_name: {
        0: "线",
        1: "矩形",
        2: "多边形",
        3: "圆形",
        4: "标签",
        5: "关键点",
        6: "文字",
        7: "标尺",
        8: "器件",
      },
      gdsType: {
        GdsFlexpath: 0,
        GdsRectangle: 1,
        GdsPolygon: 2,
        GdsEllipse: 3,
        GdsLabel: 4,
        GdsKeyPoint: 5,
        GdsText: 6,
        Ruler: 7,
        Reference: 8,
      },
      directionOption: [
        { label: "Center", value: "o" },
        { label: "Up", value: "n" },
        { label: "Down", value: "s" },
        { label: "Left", value: "w" },
        { label: "Right", value: "e" },
        { label: "upperLeft", value: "nw" },
        { label: "rightUpper", value: "ne" },
        { label: "bottomLeft", value: "sw" },
        { label: "lowerRight", value: "se" },
      ],
      showDevice: false,
      showGraph: false,
      showKeyPoint: false,
      showTag: false,
      showRuler: false,
      showText: false,
      selectIndex: "",
      selectObj: null,
      selectObj_clone: null,
      target_obj: null,
      all_layer: [],
      layerOptions: [],
      haveParams: true,
      selectParamsDevice: false,
      selectParamsDeviceParams: [],
      selectObj_params: null,
      selectDevicePatams: null,
      label_attr: null,
      deviceKeyPointList: [],
      deviceKeyPointParamsList: [],
      markImgLoading: false,
      mark_img_list: [],
      selectIndexList: [],
      select_graph_obj_list: [],
      selectObj_many_graph_layer: 0,
      selectGraphManyStart: null,
      selectGraphManyEnd: null,
      pad_length: null,
      waitChangeList: [],
      layerNumberTransObj: {},
      visible_graphList: [],
      observer: null, // Intersection Observer 实例
      old_number: 0,
      point_loading: false,
      pre_obj: null,
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    diykeyUp(e) {
      let bak = deepCopy(e.target.value);
      if (!e.target.value) {
        e.target.value = bak;
      }
      e.target.value = e.target.value.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g,
        ""
      );
      e.target.value = e.target.value.replace(/\ +/g, "");
      e.target.value = e.target.value.replace(/[\r\n]/g, "");
      e.target.value = e.target.value.replace(/[\u0391-\uFFE5]/gi, "");
    },
    numberKeyUp(e) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 3
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(3);
      }
    },
    paramsNumberKeyUp(e) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 3
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(3);
      }
    },
    // 特定字段的参数校验
    specialParamsNumberKeyChange(e, params) {
      if (
        params == "n" &&
        this.selectObj.obj_params.function_name == "Double_Channle_FDSOI"
      ) {
        if (e.target.value % 2 !== 0) {
          e.target.value = Number(e.target.value) + 1;
        }
      }
      this.changeParams();
    },
    rotateNumberKeyUp(e) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 2
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(2);
      }
    },
    radiusWidthHeightNumberKeyUp(e) {
      if (e.target.value == 0) {
        this.$message.error(this.$t("messages.radiusAndWHError"));
        e.target.value = this.old_number;
        return;
      }
      if (
        e.target.value.split(".").length > 1 &&
        e.target.value.split(".")[1].length > 3
      ) {
        let a = BigNumber(e.target.value);
        e.target.value = a.toFixed(3);
      }
    },
    toNumber(index) {
      let point = this.selectObj.shape[index];
      for (let i in point) {
        point[i] = Number(point[i]);
      }
    },
    getPointDist(x1, y1, x2, y2) {
      const p1 = { x: x1, y: y1 },
        p2 = { x: x2, y: y2 };
      return pointDist(p1, p2).toFixed(3);
    },
    getPointDist_Total(points) {
      return getPointDistTotal(points);
    },
    initStatus() {
      this.deviceList = [];
      this.graphList = [];
      this.keypointList = [];
      this.labelList = [];
    },
    initSelect() {
      this.selectAttr = true;
      this.selectParams = false;
    },
    graphClick() {
      this.selectIndex = "";
      this.selectGraphic = true;
      this.selectDevice = false;
      this.selectKeyPoint = false;
      this.selectLabel = false;
      this.selectRuler = false;
      this.selectText = false;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.select_graph_obj_list = [];
      this.selectIndexList = [];
      for (let i in this.visible_graphList) {
        this.select_graph_obj_list.push(this.visible_graphList[i]);
        this.selectIndexList.push(this.visible_graphList[i].type + "g-" + i);
      }
      this.selectObj_many_graph_layer = this.outputLayer(
        this.select_graph_obj_list
      );
      this.selectObj = this.select_graph_obj_list[0];
    },
    checkoutGraphInfo(row, index, init, e) {
      this.width = "870px";
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.selectGraphic = true;
      this.selectDevice = false;
      this.selectKeyPoint = false;
      this.selectLabel = false;
      this.selectRuler = false;
      this.selectText = false;
      this.selectIndex = row.type + "g-" + index;
      this.selectObj = row;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
      if (this.pre_obj) {
        if (this.pre_obj.obj_params.points_all) {
          this.pre_obj.obj_params.points =
            this.pre_obj.obj_params.points.concat(
              this.pre_obj.obj_params.points_all.splice(
                0,
                this.pre_obj.obj_params.points_all.length
              )
            );
          this.pre_obj.obj_params.points_all =
            this.pre_obj.obj_params.points_all.concat(
              this.pre_obj.obj_params.points.splice(
                0,
                this.pre_obj.obj_params.points.length
              )
            );
        }
      }
      this.pre_obj = row;
      if (e) {
        if (!e.shiftKey && !e.ctrlKey) {
          this.selectGraphManyStart = deepCopy(this.selectIndex);
          this.selectGraphManyEnd = null;
          this.selectIndexList = [row.type + "g-" + index];
          this.select_graph_obj_list = [row];
        }
        if (e.shiftKey) {
          this.selectGraphManyEnd = deepCopy(this.selectIndex);
          let start = Number(this.selectGraphManyStart.split("g-")[1]),
            end = Number(this.selectGraphManyEnd.split("g-")[1]);
          let sort_list = [start, end].sort();
          let select_list = [],
            select_graph_obj_list = [];
          for (let i = sort_list[0]; i < sort_list[1] + 1; i++) {
            select_list.push(this.visible_graphList[i].type + "g-" + i);
            select_graph_obj_list.push(this.visible_graphList[i]);
          }
          this.selectIndexList = select_list;
          this.select_graph_obj_list = select_graph_obj_list;
          this.selectObj_many_graph_layer = this.outputLayer(
            this.select_graph_obj_list
          );
        }
        if (e.ctrlKey) {
          if (
            this.selectIndexList.indexOf(
              this.visible_graphList[index].type + "g-" + index
            ) != -1 &&
            this.selectIndexList.length > 1
          ) {
            let getLocation = this.selectIndexList.indexOf(
              this.visible_graphList[index].type + "g-" + index
            );
            this.selectIndexList.splice(getLocation, 1);
            this.select_graph_obj_list.splice(getLocation, 1);
          } else if (
            this.selectIndexList.indexOf(
              this.visible_graphList[index].type + "g-" + index
            ) != -1 &&
            this.selectIndexList.length == 1
          ) {
          } else {
            this.select_graph_obj_list.push(this.visible_graphList[index]);
            this.selectIndexList.push(
              this.visible_graphList[index].type + "g-" + index
            );
          }
          this.selectObj_many_graph_layer = this.outputLayer(
            this.select_graph_obj_list
          );
        }
      } else {
        this.selectGraphManyStart = deepCopy(this.selectIndex);
        this.selectGraphManyEnd = null;
        this.selectIndexList = [row.type + "g-" + index];
        this.select_graph_obj_list = [row];
      }
    },
    applyAngleTransform(rotation, angle) {
      if (rotation && !angle) {
        let res = new BigNumber(rotation * (360 / (Math.PI * 2)));

        return Number(res.toFixed(0));
      } else if (!rotation && angle) {
        let res = new BigNumber(Math.PI * 2 * (angle / 360));

        return Number(res.toFixed(2));
      } else {
        return 0;
      }
    },
    checkoutDeviceInfo(row, index, init) {
      function applyEditRefTransform(points, ref) {
        return [points[0] + ref.origin[0], points[1] + ref.origin[1]];
      }
      function setKeypointFontSize(ref) {
        ref.font_size = 10;
      }
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.width = "1100px";
      this.mark_img_list = [];
      this.selectIndexList = [];
      this.select_graph_obj_list = [];
      this.selectGraphic = false;
      this.selectDevice = true;
      this.selectKeyPoint = false;
      this.selectLabel = false;
      this.selectRuler = false;
      this.selectText = false;
      this.selectIndex = row.type + "d-" + index;
      this.selectObj = row;
      this.pad_length = null;
      this.selectParamsDevice = true;
      this.thumbnail_img = true;
      this.deviceKeyPointParamsList = [];
      this.setCanvas(this.selectObj.cell);
      // 判定是否为参数化器件
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
      if (!row.cell.pcell) {
        row.cell.pcell = new Kernel.PCell();
      }
      let params_device_params = row.cell.pcell.paras;
      if (params_device_params) {
        // 参数化器件
        this.width = "1440px";
        this.haveParams = true;
        this.mark_img = true;
        let label_attr = JSON.parse(params_device_params);
        this.label_attr = label_attr;
        if (!label_attr.is_params_device) {
          this.width = "1140px";
          this.mark_img = false;
          this.haveParams = false;
          this.selectDevicePatams = deepClone(label_attr.params);
          row.obj_params.params = deepClone(label_attr.params);
          row.obj_params.function_name = label_attr.function_name;
          this.selectObj_clone = deepClone(this.selectObj_params);
        }
        this.selectParamsDeviceParams = deepClone(label_attr.params);
        row.obj_params.params = deepClone(label_attr.params);
        row.obj_params.function_name = label_attr.function_name;
        this.selectObj_clone = deepClone(this.selectObj_params);
        this.getMarkImage(label_attr.function_name);
      } else {
        this.width = "1140px";
        this.haveParams = false;
        this.mark_img = false;
        row.obj_params.params = {};
        row.obj_params.function_name = "";
        this.label_attr = {};
        this.selectObj_clone = deepClone(this.selectObj_params);
      }
      this.target_obj = row.cell;
      let labels = this.target_obj.labels;
      labels.forEach((item) => {
        if (item.get_gds_property(1)) {
          setKeypointFontSize(item);
          this.deviceKeyPointList.push(item);
          this.deviceKeyPointParamsList.push({
            name: item.text,
            origin: item.origin,
            offset: null,
            point: applyEditRefTransform(row.origin, item),
            point_origin: deepClone(applyEditRefTransform(row.origin, item)),
          });
        }
        if (item.get_gds_property(4)) {
          this.pad_length = item.get_gds_property(4);
        }
      });
      this.initSelect();
    },
    checkoutKeyPoint(row, index, init) {
      this.width = "870px";
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.selectIndexList = [];
      this.select_graph_obj_list = [];
      this.selectGraphic = false;
      this.selectDevice = false;
      this.selectKeyPoint = true;
      this.selectLabel = false;
      this.selectRuler = false;
      this.selectText = false;
      this.selectIndex = row.type + "k-" + index;
      this.selectObj = row;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
    },
    checkoutLabel(row, index, init) {
      this.width = "870px";
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.selectIndexList = [];
      this.select_graph_obj_list = [];
      this.selectGraphic = false;
      this.selectDevice = false;
      this.selectKeyPoint = false;
      this.selectLabel = true;
      this.selectRuler = false;
      this.selectText = false;
      this.selectIndex = row.type + "l-" + index;
      this.selectObj = row;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
    },
    checkoutRuler(row, index, init) {
      this.width = "870px";
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.selectIndexList = [];
      this.select_graph_obj_list = [];
      this.selectGraphic = false;
      this.selectDevice = false;
      this.selectKeyPoint = false;
      this.selectLabel = false;
      this.selectRuler = true;
      this.selectText = false;
      this.selectIndex = row.type + "r-" + index;
      this.selectObj = row;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
    },
    checkoutTextInfo(row, index, init) {
      this.width = "870px";
      if (this.selectObj.display_name == row.display_name && !init) {
        return;
      }
      this.selectIndexList = [];
      this.select_graph_obj_list = [];
      this.selectGraphic = false;
      this.selectDevice = false;
      this.selectKeyPoint = false;
      this.selectLabel = false;
      this.selectRuler = false;
      this.selectText = true;
      this.selectIndex = row.type + "t-" + index;
      this.selectObj = row;
      this.haveParams = true;
      this.selectParamsDevice = false;
      this.mark_img = false;
      this.thumbnail_img = false;
      this.initSelect();
      this.selectObj_params = row.obj_params;
      this.selectObj_clone = row.obj_params_clone;
    },
    setCanvas(obj) {
      let gds_obj = fileKernelCell2Gdstk(obj);
      setTimeout(() => {
        canvas2D.render(
          document.getElementById("thumbnail_img_device"),
          gds_obj,
          gds_obj.name
        );
      }, 100);
    },
    markUpdateObj() {
      for (let i = 0; i < this.waitChangeList.length; i++) {
        if (this.waitChangeList[i].type == 6) {
          if (
            this.waitChangeList[i].cell.text.layer !==
              this.waitChangeList[i].obj_params.layer ||
            this.waitChangeList[i].cell.text.text !==
              this.waitChangeList[i].obj_params.text ||
            this.waitChangeList[i].cell.text.size !==
              this.waitChangeList[i].obj_params.size
          ) {
            this.waitChangeList[i].need_update = true;
          }
        } else if (this.waitChangeList[i].type == 8) {
          if (this.waitChangeList[i].obj_params.params_change) {
            this.waitChangeList[i].need_update = true;
          }
        }
      }
    },
    submitUpdateAttrs(status) {
      if (this.waitChangeList.length == 0) {
        if (status) {
          this.AttrDialogVisible = false;
        }
        // this.AttrDialogVisible = false;
        return;
      }
      this.markUpdateObj();
      bus.$emit("recordProperty", this.waitChangeList);
      const reGenerateCells = [];
      for (let i = 0; i < this.waitChangeList.length; i++) {
        let obj = this.waitChangeList[i];
        // 线
        if (obj.type == 0) {
          if (obj.obj_params.trans_angle !== 0) {
            obj.angle += this.applyAngleTransform(
              null,
              obj.obj_params.trans_angle
            );
            // rotateObj(obj, obj.obj_params.trans_angle);
          } else {
            let points = obj.obj_params.points.concat(
              obj.obj_params.points_all
            );
            points = this.setPoint(points);
            obj.points = points;
          }
          // obj.center = obj.obj_params.center;
          obj.layers = obj.obj_params.layers;
          obj.width = [obj.obj_params.width, obj.obj_params.width];
          obj.radius = [obj.obj_params.radius, obj.obj_params.radius];
          obj.offset =
            obj.obj_params.center_width > 0
              ? [
                  obj.obj_params.center_width / 2 + obj.obj_params.width / 2,
                  -(obj.obj_params.center_width / 2 + obj.obj_params.width / 2),
                ]
              : [0];
          if (
            obj.layers.length > 1 &&
            obj.obj_params_clone.layers.length == 1
          ) {
            let ids = [];
            let layers = [];
            for (let i = 0; i < obj.layers.length; i++) {
              layers.push(obj.obj_params.layer);

              ids.push(this.layerNumberTransObj[obj.obj_params.layer]);
            }
            obj.layers = layers;
            obj.id = ids;
          }
        }
        // 矩形
        if (obj.type == 1) {
          obj.angle = this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
          obj.center = obj.obj_params.center;
          obj.layer = obj.obj_params.layer;
          // obj.points = obj.obj_params.points;
          obj.width = obj.obj_params.width;
          obj.height = obj.obj_params.height;
        }
        // 多边形
        if (obj.type == 2) {
          if (obj.obj_params.trans_angle !== 0) {
            obj.angle += this.applyAngleTransform(
              null,
              obj.obj_params.trans_angle
            );
            // rotateObj(obj, obj.obj_params.trans_angle);
          } else {
            let points = obj.obj_params.points.concat(
              obj.obj_params.points_all
            );
            points = this.setPoint(points);
            obj.points = points;
          }
          obj.center = obj.obj_params.center;
          obj.layer = obj.obj_params.layer;
        }
        // 圆形
        if (obj.type == 3) {
          obj.angle = this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
          obj.center = obj.obj_params.center;
          obj.layer = obj.obj_params.layer;
          obj.radius_x = obj.obj_params.radius_x;
          obj.radius_y = obj.obj_params.radius_y;
          obj.max_points = obj.obj_params.max_points;
        }
        // 标签
        else if (obj.type == 4) {
          obj.origin = obj.obj_params.origin;
          obj.angle += this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
          obj.layer = obj.obj_params.layer;
          obj.text = obj.obj_params.text;
          obj.font_size = obj.obj_params.font_size;
          obj.anchor = this.getAnchor(obj.obj_params.anchor);
        }
        // 关键点
        else if (obj.type == 5) {
          obj.origin = obj.obj_params.origin;
          obj.angle += this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
          obj.layer = obj.obj_params.layer;
          obj.text = obj.obj_params.text;
          obj.font_size = obj.obj_params.font_size;
          // obj.anchor = obj.obj_params.anchor;
        }
        // 文本图形
        else if (obj.type == 6) {
          if (obj.origin !== obj.obj_params.origin) {
            obj.origin = obj.obj_params.origin;
          }
          if (
            obj.cell.text.layer !== obj.obj_params.layer ||
            obj.cell.text.text !== obj.obj_params.text ||
            obj.cell.text.size !== obj.obj_params.size ||
            obj.obj_params.trans_angle !== 0
          ) {
            obj.cell.text.layer = obj.obj_params.layer;
            obj.cell.text.text = obj.obj_params.text;
            obj.cell.text.size = obj.obj_params.size;
            obj.rotation += this.applyAngleTransform(
              null,
              obj.obj_params.trans_angle
            );
            // obj.cell.text.anchor = this.getAnchor(obj.obj_params.anchor)
            updateCharcterCell(obj.cell, this.currentLayout.fileInfo.fileId);
            reGenerateCells.push(obj.cell);
            let cell_gns =
              this.$store.state.activeNode.parent.childNodes.filter(
                (node) => node.data.snow_id == obj.cell.snow_id
              )[0].data.gns;
            saveCellFn(obj.cell.snow_id, cell_gns, true);
          }

          obj.angle += this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
        }
        // 刻度尺
        else if (obj.type == 7) {
          if (obj.obj_params.trans_angle !== 0) {
            let angle = this.applyAngleTransform(
              null,
              obj.obj_params.trans_angle
            );
            rotateObj(obj, angle);
          } else {
            obj.points = obj.obj_params.points;
          }
        }
        // 器件
        else if (obj.type == 8) {
          let obj_repetition = obj.obj_params.repetition;
          obj.origin = obj.obj_params.origin;
          obj.rotation = this.applyAngleTransform(
            null,
            obj.obj_params.trans_angle
          );
          obj.repetition.rows = obj_repetition.rows;
          obj.repetition.columns = obj_repetition.columns;
          obj.repetition.spacing = obj_repetition.spacing;
          obj.repetition.v1 = obj_repetition.v1;
          obj.repetition.v2 = obj_repetition.v2;
          if (obj.obj_params.params_change) {
            this.updateCell(obj);
            reGenerateCells.push(obj.cell);
          }
        }

        // return;

        // return;
        //******只更新cell内部数据 重渲染*//
        if (reGenerateCells.length) {
          //涉及更新其他cell数据 重渲染*//
          this.currentLayout.updateByMessageServe();
          this.currentLayout.STATUS.updateCheckStatus();
          this.currentLayout.STAGE.updateCheckedRefs(this.currentLayout);
          this.currentLayout.STAGE.updateRefArray(this.currentLayout);
        } else {
          reBuildGDSGraphic(obj, this.currentLayout, true, true, true);
          this.currentLayout.STATUS.updateCheckStatus();
          this.currentLayout.STAGE.updateCheckedRefs(this.currentLayout);
          this.currentLayout.STAGE.updateRefArray(this.currentLayout);
        }
        updateObjMat(obj, this.currentLayout, true);
        this.currentLayout.reRender();
      }
      if (status) {
        this.AttrDialogVisible = false;
      }
      this.waitChangeList = [];
    },
    cancelUpdateAttrs() {
      this.AttrDialogVisible = false;
      // this.selectObj_params = this.selectObj_clone;
    },
    copyPoint(index) {
      const points = deepClone(this.selectObj_params.points[index]);
      this.selectObj_params.points.splice(index, 0, points);
      this.recordChange();
    },
    // 点坐标去重 bug只去处相邻坐标
    setPoint(points) {
      let set_point = [];
      let before = null;
      points.forEach((point) => {
        if (before && before.toString() == point.toString()) {
          //相邻坐标重复
        } else {
          before = point;
          set_point.push(point);
        }
      });
      return set_point;
    },
    checkPoint() {
      if (this.selectObj.type == 0 && this.selectObj_params.points.length < 3) {
        this.$message.error("直线不能少于两个点");
        return false;
      }
      if (this.selectObj.type == 2 && this.selectObj_params.points.length < 4) {
        this.$message.error("多边形不能少于三个点");
        return false;
      }
      let set_points = this.setPoint(this.selectObj_params.points);

      if (this.selectObj.type == 0 && set_points.length < 3) {
        this.$message.error("去除重复点后直线不能少于两个点");
        return false;
      }
      if (this.selectObj.type == 2 && set_points.length < 4) {
        this.$message.error("去除重复点后多边形不能少于三个点");
        return false;
      }
      return true;
    },
    changePointCheck(val, row_point, index) {
      let set_points = this.setPoint(this.selectObj_params.points);
      if (this.selectObj.type == 0 && set_points.length < 2) {
        this.$message.error("去除重复点后直线不能少于两个点");
        row_point[index] = this.old_number;
      }
      if (this.selectObj.type == 2 && set_points.length < 3) {
        this.$message.error("去除重复点后多边形不能少于三个点");
        row_point[index] = this.old_number;
      }
    },
    onInputFocus(e) {
      this.old_number = e.target.value;
    },
    deletePoint(idnex) {
      if (!this.checkPoint()) {
        return;
      }
      this.selectObj_params.points.splice(idnex, 1);
      this.recordChange();
    },
    recordChange() {
      if (!_.isEqual(this.selectObj_params, this.selectObj_clone)) {
        let res = this.waitChangeList.some(
          (item) => item.obj_params.snow_id == this.selectObj_params.snow_id
        );
        if (!res) {
          this.waitChangeList.push(this.selectObj);
        }
      }
    },
    recordListChange() {
      for (let i in this.select_graph_obj_list) {
        if (
          !_.isEqual(
            this.select_graph_obj_list[i].obj_params,
            this.select_graph_obj_list[i].obj_params_clone
          )
        ) {
          let res = this.waitChangeList.some(
            (item) =>
              item.obj_params.snow_id ==
              this.select_graph_obj_list[i].obj_params.snow_id
          );
          if (!res) {
            this.waitChangeList.push(this.select_graph_obj_list[i]);
          }
        }
      }
    },
    recordDevice() {
      if (this.selectObj.obj_params.params_change) {
        let res = this.waitChangeList.some(
          (item) => item.obj_params.snow_id == this.selectObj_params.snow_id
        );
        if (!res) {
          this.waitChangeList.push(this.selectObj);
        }
        return;
      }
      let origin_params = JSON.parse(this.selectObj.cell.pcell.paras).params;
      if (!_.isEqual(this.selectObj.obj_params.params, origin_params)) {
        let res = this.waitChangeList.some(
          (item) => item.obj_params.snow_id == this.selectObj_params.snow_id
        );
        if (!res) {
          this.waitChangeList.push(this.selectObj);
        }
      }
    },
    checkPDKObj(obj) {
      let labels = obj.labels;
      let isPDK = false;
      for (let i in labels) {
        if (labels[i].get_gds_property(5)) {
          isPDK = true;
          break;
        }
      }
      return isPDK;
    },
    changeParams() {
      this.$forceUpdate();
      let function_name = this.selectObj.obj_params.function_name;
      let target_obj_cell = null;
      let isPDK = this.checkPDKObj(this.target_obj);
      if (isPDK) {
        target_obj_cell = this.getPDKCell(function_name);
      } else {
        target_obj_cell = this.getCell(function_name);
      }
      this.selectObj.obj_params.params_change = true;
      this.recordDevice();
      setTimeout(() => {
        canvas2D.render(
          document.getElementById("thumbnail_img_device"),
          target_obj_cell,
          target_obj_cell.name
        );
      }, 100);
    },
    changeLayerParams() {
      this.$forceUpdate();
      this.changeParams();
      // this.selectObj.obj_params.params_change = true;
      // this.recordDevice();
    },
    setPadLength(target_obj) {
      let real_labels = target_obj.cells[0].labels;

      real_labels.forEach((item) => {
        if (item.get_gds_property(4)) {
          this.pad_length = item.get_gds_property(4);
        }
      });
    },
    getCell(name) {
      let obj = getCellFunc(
        name,
        this.selectObj.obj_params.params,
        this.selectObj.cell
      );
      this.setPadLength(obj);
      return obj;
    },
    getPDKCell(name) {
      let obj = getPDKCellFunc(
        name,
        this.selectObj.obj_params.params,
        this.selectObj.cell
      );
      this.setPadLength(obj);
      return obj;
    },
    getLayerData(fileID) {
      let allData = this.$store.state.fileLayerListTable;
      for (let i in allData) {
        if (fileID == allData[i].fileId) {
          return allData[i].fileLayerDatas;
        }
      }
    },
    updateCell(obj) {
      let old_params = deepClone(JSON.parse(obj.cell.pcell.paras));
      obj.cell = exChangeCpmponent(
        obj.cell,
        obj.obj_params,
        this.currentLayout.fileInfo.fileId
      );

      let params_json = JSON.parse(obj.cell.pcell.paras);
      let params = {
        old_params: old_params,
        new_params: params_json,
      };

      let cell_gns = this.$store.state.activeNode.parent.childNodes.filter(
        (node) => node.data.snow_id == obj.cell.snow_id
      )[0].data.gns;
      saveCellFn(obj.cell.snow_id, cell_gns, true);
      bus.$emit("update_cell", params);
    },
    updateStaticCell() {
      let params = {
        position: this.selectObj.obj_params.origin,
        rotation: this.selectObj.obj_params.angle,
        reference: this.selectObj.reference,
      };

      bus.$emit("update_static_cell", params);
    },
    layerChange(val) {
      if (this.selectObj.type == 0) {
        let ids = [];
        for (let i in this.selectObj.obj_params.layers) {
          this.selectObj.obj_params.layers[i] = val;
          ids.push(this.layerNumberTransObj[val]);
        }
        this.selectObj.id = ids;
      } else if (this.selectObj.type == 6) {
        this.recordChange();
        return;
      } else {
        this.selectObj.id = this.layerNumberTransObj[val];
      }
      this.recordChange();
    },
    layerListChange(val) {
      for (let i in this.select_graph_obj_list) {
        let obj = this.select_graph_obj_list[i];
        obj.obj_params.layer = val;
        if (typeof obj.id == "string") {
          obj.id = this.layerNumberTransObj[val];
        } else {
          let ids = [];
          for (let j in obj.obj_params.layers) {
            obj.obj_params.layers[j] = val;
            ids.push(this.layerNumberTransObj[val]);
          }
          obj.id = ids;
        }
      }
      this.recordListChange();
    },
    getMarkImage(name) {
      if (!this.haveParams) {
        return;
      }
      let params = {
        name: name,
      };
      this.markImgLoading = true;
      getMarkImg(params).then((res) => {
        this.mark_img_list = res.data.data[0].mark_img.mark_img;
      });
      this.markImgLoading = false;
    },
    layerTransForm(k) {
      let k_sp = k.split("_");
      let r_k = "";

      if (k_sp.length > 1) {
        if (k_sp.length == 2) {
          r_k = k_sp[1].toUpperCase();
        } else {
          k_sp.shift();

          r_k = k_sp.join("-").toUpperCase();
        }
      } else {
        r_k = "Default layer";
      }
      return r_k;
    },
    outputLayer(graphList) {
      // 判断共有图层编号
      let layer_list = [];
      for (let i in graphList) {
        layer_list.push(graphList[i].layer);
      }
      layer_list = Array.from(new Set(layer_list));
      if (layer_list.length == 1) {
        return layer_list[0];
      } else {
        return null;
      }
    },
    getAnchor(anchor) {
      if (anchor == "o") {
        return Kernel.GdsAnchor.O;
      }
      if (anchor == "n") {
        return Kernel.GdsAnchor.N;
      }
      if (anchor == "s") {
        return Kernel.GdsAnchor.S;
      }
      if (anchor == "w") {
        return Kernel.GdsAnchor.W;
      }
      if (anchor == "e") {
        return Kernel.GdsAnchor.E;
      }
      if (anchor == "nw") {
        return Kernel.GdsAnchor.NW;
      }
      if (anchor == "ne") {
        return Kernel.GdsAnchor.NE;
      }
      if (anchor == "sw") {
        return Kernel.GdsAnchor.SW;
      }
      if (anchor == "se") {
        return Kernel.GdsAnchor.SE;
      }
    },
    anchorTransForm(anchor) {
      if (Kernel.GdsAnchor.O == anchor) {
        return "o";
      }
      if (Kernel.GdsAnchor.N == anchor) {
        return "n";
      }
      if (Kernel.GdsAnchor.S == anchor) {
        return "s";
      }
      if (Kernel.GdsAnchor.W == anchor) {
        return "w";
      }
      if (Kernel.GdsAnchor.E == anchor) {
        return "e";
      }
      if (Kernel.GdsAnchor.NW == anchor) {
        return "nw";
      }
      if (Kernel.GdsAnchor.NE == anchor) {
        return "ne";
      }
      if (Kernel.GdsAnchor.SW == anchor) {
        return "sw";
      }
      if (Kernel.GdsAnchor.SE == anchor) {
        return "se";
      }
    },
    getRepetition(obj) {
      let obj_repetition = {};
      if (obj.repetition) {
        obj_repetition.rows = obj.repetition.rows;
        obj_repetition.columns = obj.repetition.columns;
        obj_repetition.spacing = obj.repetition.spacing;
        obj_repetition.v1 = obj.repetition.v1;
        obj_repetition.v2 = obj.repetition.v2;
      }
      return obj_repetition;
    },
    changeDeviceKeyPoint(row) {
      row.offset = [
        row.point[0] - row.point_origin[0],
        row.point[1] - row.point_origin[1],
      ];
      this.selectObj.obj_params.origin = [
        this.selectObj.origin[0] + row.offset[0],
        this.selectObj.origin[1] + row.offset[1],
      ];
    },
    loadItems() {
      // 模拟异步加载数据
      setTimeout(() => {
        // 假设获取到了新的数据项
        if (this.graphList.length > 0) {
          const newItems = this.graphList.splice(0, 10);
          this.visible_graphList = [...this.visible_graphList, ...newItems];
        }
      }, 500);
    },
    handleIntersection(entries) {
      if (this.graphList.length == 0) {
        this.observer.disconnect();
        return;
      }
      if (!this.showGraph) return;
      // 处理 Intersection Observer 的回调
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 当 sentinel 元素进入视窗时加载更多数据
          this.loadItems();
        }
      });
    },
    opened() {
      // 监听 sentinel 元素
      this.observer.observe(this.$refs.sentinel);
    },
    loadMore() {
      if (!this.selectObj_params.points_all) {
        return;
      }
      if (
        this.selectObj_params.points_all &&
        this.selectObj_params.points_all.length == 0
      ) {
        return;
      }
      this.loadData();
    },
    loadData() {
      if (!this.selectObj_params.points_all) {
        return;
      }
      if (
        this.selectObj_params.points_all &&
        this.selectObj_params.points_all.length == 0
      ) {
        return;
      }
      this.point_loading = true;
      setTimeout(() => {
        let newItems = this.selectObj_params.points_all.splice(0, 20);
        newItems.forEach((item) => {
          this.selectObj_params.points.push(item);
        });
        this.point_loading = false;
      }, 200);
    },
    initPointData() {
      if (!this.selectObj_params.points_all) {
        return;
      }
      if (this.selectObj_params.points.length == 0) {
        return;
      }
      let points = this.selectObj_params.points.concat(
        this.selectObj_params.points_all
      );
      this.selectObj_params.points = [];
      this.selectObj_params.points_all = points;
    },
  },
  mounted() {
    bus.$on("openAttrs", (layout) => {
      this.currentLayout = layout;
      let data = this.currentLayout.STATUS.checked_objs;
      if (data.length == 0) return;
      this.deviceList = [];
      this.graphList = [];
      this.visible_graphList = [];
      this.labelList = [];
      this.keypointList = [];
      this.textList = [];
      this.rulerList = [];
      this.all_layer = this.getLayerData(this.$store.state.fileInfo.fileId);
      this.layerOptions = this.all_layer.map((item) => {
        let obj = { label: item.layerName, value: item.layerNumber };
        return Object.assign(item, obj);
      });
      this.layerNumberTransObj = {};
      for (let i = 0; i < this.layerOptions.length; i++) {
        this.layerNumberTransObj[this.layerOptions[i].value] =
          this.layerOptions[i].id;
      }
      this.AttrDialogVisible = false;
      for (let i = 0; i < data.length; i++) {
        data[i].need_update = false;
        if (data[i].$$?.ptrType.name) {
          data[i].type =
            this.gdsType[data[i].$$?.ptrType.name.replace("*", "")];
          if (data[i].$$?.ptrType.name == "Reference*") {
            if (data[i].cell.text) {
              data[i].type = 6;
            } else {
              data[i].type = 8;
            }
          } else {
            data[i].type =
              this.gdsType[data[i].$$?.ptrType.name.replace("*", "")];
          }
        } else {
          data[i].type = this.gdsType[data[i].constructor.name];
        }

        // 基础图形
        if (data[i].type < 4) {
          this.graphList.push(data[i]);
          this.showGraph = true;
        }
        // 标签
        else if (data[i].type == 4) {
          this.labelList.push(data[i]);
          this.showTag = true;
        }
        // 关键点
        else if (data[i].type == 5) {
          this.keypointList.push(data[i]);
          this.showKeyPoint = true;
        }
        // 文本图形
        else if (data[i].type == 6) {
          this.textList.push(data[i]);
          this.showText = true;
        }
        // 刻度尺
        else if (data[i].type == 7) {
          this.rulerList.push(data[i]);
          this.showRuler = true;
        }
        // 器件
        else if (data[i].type == 8) {
          this.deviceList.push(data[i]);
          this.showDevice = true;
        }
      }
      for (let i = 0; i < this.graphList.length; i++) {
        this.graphList[i].display_name =
          this.graph_name[this.graphList[i].type] + i;
      }
      for (let i = 0; i < this.labelList.length; i++) {
        this.labelList[i].display_name =
          this.graph_name[this.labelList[i].type] + i;
      }
      for (let i = 0; i < this.keypointList.length; i++) {
        this.keypointList[i].display_name =
          this.graph_name[this.keypointList[i].type] + i;
      }
      for (let i = 0; i < this.textList.length; i++) {
        this.textList[i].display_name =
          this.graph_name[this.textList[i].type] + i;
      }
      for (let i = 0; i < this.rulerList.length; i++) {
        this.rulerList[i].display_name =
          this.graph_name[this.rulerList[i].type] + i;
      }
      for (let i = 0; i < this.deviceList.length; i++) {
        this.deviceList[i].display_name =
          this.graph_name[this.deviceList[i].type] + i;
      }
      let all_data = [
        ...this.deviceList,
        ...this.graphList,
        ...this.labelList,
        ...this.keypointList,
        ...this.textList,
        ...this.rulerList,
      ];
      for (let i = 0; i < all_data.length; i++) {
        let obj_params = null;
        let obj_params_clone = null;
        if (all_data[i].type == 0) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            center: all_data[i].center,
            layer: all_data[i].layers[0],
            layers: all_data[i].layers,
            points: [],
            points_all: all_data[i].points,
            total_points: all_data[i].points.length,
            width: all_data[i].width[0],
            radius: all_data[i].radius[0],
            type: all_data[i].type,
            snow_id: Kernel.snowid(),
            center_width:
              all_data[i].offset.length > 1
                ? all_data[i].offset[0] +
                  Math.abs(all_data[i].offset[1]) -
                  all_data[i].width[0]
                : 0,
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 1) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            center: all_data[i].center,
            layer: all_data[i].layer,
            points: all_data[i].points,
            width: all_data[i].width,
            height: all_data[i].height,
            type: all_data[i].type,
            snow_id: Kernel.snowid(),
            trans_angle: this.applyAngleTransform(all_data[i].angle, null),
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 2) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            center: all_data[i].center,
            points: [],
            points_all: all_data[i].points,
            total_points: all_data[i].points.length,
            layer: all_data[i].layer,
            type: all_data[i].type,
            snow_id: Kernel.snowid(),
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 3) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            center: all_data[i].center,
            layer: all_data[i].layer,
            radius_x: all_data[i].radius_x,
            radius_y: all_data[i].radius_y,
            type: all_data[i].type,
            max_points: all_data[i].max_points,
            snow_id: Kernel.snowid(),
            trans_angle: this.applyAngleTransform(all_data[i].angle, null),
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 4) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            origin: all_data[i].origin,
            // angle: all_data[i].angle,
            layer: all_data[i].layer,
            text: all_data[i].text,
            font_size: all_data[i].font_size,
            anchor: this.anchorTransForm(all_data[i].anchor),
            snow_id: Kernel.snowid(),
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 5) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            origin: all_data[i].origin,
            layer: all_data[i].layer,
            text: all_data[i].text,
            font_size: all_data[i].font_size,
            // anchor: all_data[i].anchor,
            snow_id: Kernel.snowid(),
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 6) {
          let text_obj = all_data[i].cell.text;
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angel: all_data[i].angel,
            origin: all_data[i].origin,
            layer: text_obj.layer,
            size: text_obj.size,
            text: text_obj.text,
            anchor: this.anchorTransForm(text_obj.anchor),
            snow_id: Kernel.snowid(),
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 7) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            // angle: all_data[i].angle,
            points: all_data[i].points,
            snow_id: Kernel.snowid(),
            trans_angle: 0,
          });
          obj_params_clone = deepClone(obj_params);
        } else if (all_data[i].type == 8) {
          obj_params = deepClone({
            display_name: all_data[i].display_name,
            rotation: all_data[i].rotation,
            origin: all_data[i].origin,
            snow_id: all_data[i].cell.snow_id,
            trans_angle: this.applyAngleTransform(all_data[i].rotation, null),
            repetition: this.getRepetition(all_data[i]),
            params_change: false,
          });
          obj_params_clone = deepClone(obj_params);
        }
        all_data[i].obj_params = obj_params;
        all_data[i].obj_params_clone = obj_params_clone;
      }
      this.selectObj = all_data[0];
      if (this.selectObj.type == 0) {
        // 直线
        this.checkoutGraphInfo(this.selectObj, 0, true, null);
      } else if (this.selectObj.type == 1) {
        // 矩形
        this.checkoutGraphInfo(this.selectObj, 0, true, null);
      } else if (this.selectObj.type == 2) {
        // 多边形
        this.checkoutGraphInfo(this.selectObj, 0, true, null);
      } else if (this.selectObj.type == 3) {
        // 圆形
        this.checkoutGraphInfo(this.selectObj, 0, true, null);
      } else if (this.selectObj.type == 4) {
        // 标签
        this.checkoutLabel(this.selectObj, 0, true);
      }
      // 关键点
      else if (this.selectObj.type == 5) {
        this.checkoutKeyPoint(this.selectObj, 0, true);
      }
      // 文本图形
      else if (this.selectObj.type == 6) {
        this.checkoutTextInfo(this.selectObj, 0, true);
      } else if (this.selectObj.type == 7) {
        // 标尺
        this.checkoutRuler(this.selectObj, 0, true);
      }
      // 器件
      else if (this.selectObj.type == 8) {
        this.checkoutDeviceInfo(this.selectObj, 0, true);
      }
      this.AttrDialogVisible = true;
      // 初始化 Intersection Observer
      this.observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });
      // 加载初始数据
      this.loadItems();
    });
  },
  beforeUnmount() {
    // 在组件销毁前取消 Intersection Observer 的监听
    this.observer.disconnect();
  },
  watch: {
    input: {
      handler() {},
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 20px;
  color: #333;
  .dialog-body {
    display: flex;
  }
  .dialog-obj {
    display: inline-block;
    width: 210px;
    height: 500px;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 10px;
    .dialog-obj__title {
      font-size: 12px;
      color: #666666;
      padding: 10px;
      border-bottom: 1px solid #dddddd;
    }
    .dialog-obj__body {
      font-size: 12px;
      color: #666666;
      height: 460px;
      overflow-y: auto;
      .obj-item {
        padding: 8px 10px;
        cursor: pointer;
        .item-icom {
          color: #999999;
        }
        .item__item {
          font-size: 12px;
          color: #666666;
          padding: 4px 18px;
          width: 82%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item__item:hover {
          background: #e4eaf5;
        }
        .selected {
          color: #0c7de6;
          background: #dce6f8;
        }
      }
    }
  }
  .dialog-main {
    display: inline-block;
    width: 560px;
    height: 500px;
    overflow-y: auto;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 10px;
    .main-tab {
      border-bottom: 1px solid #dddddd;
      position: relative;
      .tab-item {
        font-size: 12px;
        color: #666666;
        display: inline-block;
        padding: 9px 10px;
        cursor: pointer;
      }
    }
    .selected {
      color: #4385f2;
      border-bottom: 2px solid #0c7de6;
    }
    .main-body {
      padding: 12px 16px;
      .body-item {
        font-size: 12px;
        color: #666666;
        margin-bottom: 10px;
        display: inline-block;
        .item-title {
          width: 120px;
        }
        .item-label {
          width: 150px;
        }
        div {
          display: inline-block;
        }
        /deep/ .el-input {
          width: auto;
        }
        /deep/ .el-input-number {
          display: inline-block;
          width: auto;
        }
        .input-diabled {
          height: 34px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          width: 175px;
          line-height: 34px;
          text-align: center;
          background: #cccccc;
        }
      }
    }
  }
}
/deep/ .el-input__inner {
  height: 34px;
  background: #ffffff !important;
  border: 1px solid #dddddd;
  border-radius: 2px;
  text-align: left;
}
/deep/ .el-input__inner[disabled],
input:disabled,
input.disabled {
  background: #cccccc !important;
  -webkit-opacity: 1;
  opacity: 1;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--default {
  background: #4385f2;
}
.Ann_image {
  display: inline-block;
  background: #f4f4f4;
  width: 275px;
  height: 500px;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-right: 10px;
  .Ann_image__header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    position: relative;
    // padding: 10px;
  }
}
.Ann_image__body {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 40px);
  flex-direction: column;
  .ann-image {
    width: 100%;
    margin: auto;
    cursor: pointer;
  }
}
.Ann_image__body_2D {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 40px);
  flex-direction: column;
  background-color: #000000;
  #thumbnail_img_device {
    margin: auto;
  }
}
.t-table_keyPoint {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-input {
    position: static !important;
    width: auto !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  /deep/ .el-table__header-wrapper {
    position: relative;
    top: 2px;
    margin: -2px 0;
  }
  /deep/ .el-table__body-wrapper {
    background: #f4f4f4;
  }
  /deep/ .el-table__cell {
    border-bottom: none !important;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input__inner {
    width: 100px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: left;
    display: inline-block;
    text-indent: 10px;
  }
  /deep/ .el-input__inner[disabled],
  input:disabled,
  input.disabled {
    background: #cccccc !important;
    -webkit-opacity: 1;
    opacity: 1;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
    vertical-align: bottom;
  }
  .distabce-1 {
    font-size: 14px;
  }
  .distabce-2 {
    font-size: 12px;
    position: relative;
    top: 5px;
  }
  /deep/ .el-input-number {
    width: auto;
  }
}
.t-table {
  width: 100%;
  background: #ffffff;
  /deep/ .table_header {
    background: #e9eff3 !important;
  }
  /deep/ .el-input {
    position: static !important;
    width: auto !important;
  }
  /deep/ .el-table__cell {
    padding: 3px !important;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    background: #f4f4f4;
  }
  /deep/ .el-table__header-wrapper {
    position: relative;
    top: 2px;
    margin: -2px 0;
  }
  /deep/ .el-table__body-wrapper {
    background: #f4f4f4;
  }
  /deep/ .el-table__cell {
    border-bottom: none !important;
  }
  .disabled {
    color: #b2b2b2 !important;
  }
  /deep/ .el-input__inner {
    width: 59px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 0 !important;
    text-align: left;
    display: inline-block;
    text-indent: 10px;
  }
  /deep/ .el-input__inner[disabled],
  input:disabled,
  input.disabled {
    background: #cccccc !important;
    -webkit-opacity: 1;
    opacity: 1;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
    vertical-align: bottom;
  }
  .distabce-1 {
    font-size: 14px;
  }
  .distabce-2 {
    font-size: 12px;
    position: relative;
    top: 5px;
  }
  /deep/ .el-input-number {
    width: auto;
  }
}
.distabce-1 {
  font-size: 14px;
}
.distabce-2 {
  font-size: 12px;
  position: relative;
  top: 5px;
}
.tool-main__normal {
  width: 100%;
  display: flex;
  margin-top: 10px;
  .main-group {
    padding: 10px 0;
    .main-label {
      display: inline-block;
      width: 35%;
    }
    /deep/ .el-input-number {
      width: auto;
    }
    .el-input {
      width: auto;
    }
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
      background: #ffffff !important;
      border: 1px solid #dddddd;
      border-radius: 2px;
      padding: 0 !important;
      text-align: left;
      display: inline-block;
    }
    /deep/ .el-input__inner[disabled],
    input:disabled,
    input.disabled {
      background: #cccccc !important;
      -webkit-opacity: 1;
      opacity: 1;
    }
  }
}
</style>
